.main-container {
  width: 100vw;
  padding-top: 50px;

  ul {
    margin-bottom: 0;
  }

  form {
    width: 800px;
    margin-right: auto;
    margin-left: auto;

    .header-wrapper {
      display: flex;
      justify-content: space-between;

      button {
        background: none;
        color: #6c6c6c;
        border: none;
        font: inherit;
        cursor: pointer;
        font-size: 18px;
      }
    }


    .dropzone {
      height: 300px;
      width: 100%;
      background: #FAFAFA;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed #EEEEEE;
      cursor: pointer;
      transition: border-color 100ms linear;

      &:hover {
        border-color: #2999F2;
      }
    }

    button[type=submit] {
      background: none;
      color: #6c6c6c;
      font: inherit;
      margin-top: 5px;
      padding: 5px 15px;
      cursor: pointer;
      outline: inherit;
      font-size: 24px;
      border-width: 1px;
      border-radius: 3px;
    }
  }
}